import { useSelector } from 'react-redux';

export const usePermission = () => {
    const auth = useSelector(state => state.auth);

    // Return null if auth is still loading
    if (auth.loading) {
        console.log('Auth is still loading');
        return { userCan: () => false };
    }

    const userCan = (permission) => {

        // Check if the user has superadmin role
        if (auth?.user?.role_name === 'superadmin') {
            return true;
        }
        // Check in all_permissions array (these are the user's actual permissions)
        const hasPermission = auth?.permissions?.all_permissions?.includes(permission);

        return hasPermission || false;
    };

    return { userCan };
};
