import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteExpenseId, setUpdateExpenseId} from "../../actions/expense";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { usePermission } from '../../hooks/usePermission';

const formatMoney = (value, seperator = ',') => {
    if (value) {
        return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, seperator);
    } else
        return 0;
}

const ExpenseItem = ({
    setDeleteExpenseId,
    setUpdateExpenseId,
    number,
    expense: {
        id, price, quantity, description,
        expense_template,
        date,
        unit,
        firm,
        created_at
    }
}) => {
    const { userCan } = usePermission();

    if (!userCan('view expenses')) {
        return null;
    }

    const setDeleteExpense = (id) => {
        setDeleteExpenseId(id);
    };

    const setUpdateExpense = (id) => {
        setUpdateExpenseId(id);
    };

    return (
        <tr>
            <th scope="row">{number}</th>
            <td>{expense_template?.name}</td>
            <td>{expense_template?.category}</td>
            <td>{firm?.name || 'N/A'}</td>
            <td>{quantity} {unit?.symbol}</td>
            <td>{formatMoney(price)} so'm</td>
            <td>{formatMoney(price * quantity)} so'm</td>
            <td>{date ? moment(date).format('DD.MM.YYYY') : '-'}</td>
            <td>{description}</td>
            <td>{moment(created_at).format('DD.MM.YYYY HH:mm')}</td>
            <td>
                <div className="d-flex gap-2">
                    {userCan('edit expenses') && (
                        <Button
                            variant="outline-primary"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                            onClick={() => setUpdateExpense(id)}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    )}
                    {userCan('delete expenses') && (
                        <Button
                            variant="outline-danger"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                            onClick={() => setDeleteExpense(id)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    )}
                </div>
            </td>
        </tr>
    );
};

ExpenseItem.propTypes = {
    expense: PropTypes.shape({
        id: PropTypes.number.isRequired,
        price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        quantity: PropTypes.number.isRequired,
        description: PropTypes.string,
        expense_template: PropTypes.shape({
            name: PropTypes.string,
            category: PropTypes.string
        }),
        date: PropTypes.string,
        unit: PropTypes.shape({
            symbol: PropTypes.string
        }),
        firm: PropTypes.shape({
            name: PropTypes.string
        }),
        created_at: PropTypes.string.isRequired
    }).isRequired,
    number: PropTypes.number.isRequired,
    setDeleteExpenseId: PropTypes.func.isRequired,
    setUpdateExpenseId: PropTypes.func.isRequired
};

export default connect(null, {setDeleteExpenseId, setUpdateExpenseId})(ExpenseItem);
