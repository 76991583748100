import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Space, message } from 'antd';
import axios from 'axios';

const { Option } = Select;

const PermissionForm = ({ initialValues, onSave, onCancel }) => {
    const [form] = Form.useForm();
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchPages();
        if (initialValues) {
            const formValues = {
                ...initialValues,
                page_ids: initialValues.pages?.map(page => page.id) || []
            };
            form.setFieldsValue(formValues);
        }
    }, [initialValues, form]);

    const fetchPages = async () => {
        try {
            const response = await axios.get('/pages');
            setPages(response.data.data || []);
        } catch (error) {
            message.error('Failed to fetch pages');
        }
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            await onSave({
                ...values,
                guard_name: 'api'
            });
            form.resetFields();
        } catch (error) {
            console.error('Error saving permission:', error);
        }
        setLoading(false);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={initialValues}
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please enter permission name' }]}
            >
                <Input placeholder="e.g., create_users" />
            </Form.Item>

            <Form.Item
                name="display_name"
                label="Display Name"
                rules={[{ required: true, message: 'Please enter display name' }]}
            >
                <Input placeholder="e.g., Create Users" />
            </Form.Item>

            <Form.Item
                name="page_ids"
                label="Pages"
            >
                <Select
                    mode="multiple"
                    placeholder="Select pages"
                    style={{ width: '100%' }}
                >
                    {pages.map(page => (
                        <Option key={page.id} value={page.id}>
                            {page.display_name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Save
                    </Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </Space>
            </Form.Item>
        </Form>
    );
};

export default PermissionForm;
