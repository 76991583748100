import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import MaterialDeleteForm from "./MaterialDeleteForm";
import MaterialUpdateForm from "./MaterialUpdateForm";
import {getMaterials} from "../../actions/material";
import MaterialCreateForm from "./MaterialCreateForm";
import MaterialItem from "./MaterialItem";
import SearchForm from "../layout/SearchForm";
import MaterialImportForm from "./MaterialImportForm";
import FilterByStock from "./FilterByStock";
import { Plus} from "react-feather";
import FilterByFirm from "../filters/FilterByFirm";
import {Accordion} from "react-bootstrap";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";
import TableSpinner from "../layout/TableSpinner";
import { usePermission } from '../../hooks/usePermission';

const Materials = () => {
    const { userCan } = usePermission();
    const {materials, loading, numberOfPages, currentPage} = useSelector(state => state.material);
    const [show, setShow] = useState(false);

    if (!userCan('view materials')) {
        return <div>You don't have permission to view materials.</div>;
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Fragment>
            <div className="container-fluid p-0">
                {userCan('import materials') && (
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className={'fs-4'}>Material<strong> import qilish</strong></div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <MaterialImportForm/>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                )}

                <div className="row mt-4">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h5 mb-3"><strong>Materiallar</strong>({materials.length}) </h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-3">
                        <FilterByFirm getPageItems={getMaterials}/>
                    </div>

                    <div className="col-3">
                        <SearchForm getPageItems={getMaterials}/>
                    </div>

                    <div className="col-3">
                        <FilterByStock getPageItems={getMaterials}/>
                    </div>

                    <div className="col-md-3">
                        <div className="crud_actions text-end">
                            {userCan('create materials') && (
                                <button className="btn btn-primary" onClick={handleShow}>
                                    Material
                                    <Plus/>
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <div className="card w-100 table-responsive">
                            {loading && <TableSpinner/>}
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nomi</th>
                                    <th scope="col">Firma</th>
                                    <th scope="col">Sklad</th>
                                    <th scope="col">Miqdori</th>
                                    <th scope="col">Kirim o'lchovi</th>
                                    <th scope="col">=</th>
                                    <th scope="col">Chiqim o'lchovi</th>
                                    <th scope="col">So'nggi Narxi</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {materials.map((item, index) => (
                                    <MaterialItem
                                        key={item.id}
                                        material={item}
                                        number={index + 1}
                                    />
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPaginationWithManyPages
                            numberOfPages={numberOfPages}
                            getPageItems={getMaterials}
                            current_page={currentPage}
                        />
                    </div>
                </div>
            </div>

            {userCan('create materials') && (
                <MaterialCreateForm
                    show={show}
                    handleClose={handleClose}
                />
            )}

            {userCan('edit materials') && (
                <MaterialUpdateForm/>
            )}

            {userCan('delete materials') && (
                <MaterialDeleteForm/>
            )}
        </Fragment>
    );
};

Materials.propTypes = {
    getMaterials: PropTypes.func.isRequired
};

export default connect(null, {getMaterials})(Materials);
