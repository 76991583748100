import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteUnitId, setUpdateUnitId} from "../../actions/unit";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { usePermission } from '../../hooks/usePermission';

const UnitItem = ({
                      setDeleteUnitId,
                      setUpdateUnitId,
                      unit: {
                          id, name, symbol, value,
                          date: created_at
                      },
                      showActions,
                      number
                  }) => {
    const { userCan } = usePermission();

    if (!userCan('view units')) {
        return null;
    }

    const setDeleteUnit = (id) => {
        setDeleteUnitId(id);
    };

    const setUpdateUnit = (id) => {
        setUpdateUnitId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>{symbol}</td>
                <td>{value}</td>
                <td>
                    <div className="d-flex gap-2">
                        {userCan('edit units') && (
                            <Button
                                variant="outline-primary"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                                onClick={() => setUpdateUnit(id)}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        )}
                        {userCan('delete units') && (
                            <Button
                                variant="outline-danger"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                                onClick={() => setDeleteUnit(id)}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        )}
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

UnitItem.defaultProps = {
    showActions: true,
};

UnitItem.propTypes = {
    unit: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        symbol: PropTypes.string,
        value: PropTypes.number,
        date: PropTypes.string
    }).isRequired,
    showActions: PropTypes.bool,
    number: PropTypes.number.isRequired,
    setDeleteUnitId: PropTypes.func.isRequired,
    setUpdateUnitId: PropTypes.func.isRequired
};

export default connect(null, {setDeleteUnitId, setUpdateUnitId})(UnitItem);
