import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteProductId, setUpdateProductId} from "../../actions/product";
import {Link} from 'react-router-dom'
import MoneyFormat from "../layout/MoneyFormat";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { usePermission } from '../../hooks/usePermission';

const ProductItem = ({
                         setDeleteProductId,
                         setUpdateProductId,
                         handleShow,
                         handleClose,
                         product: item,
                         index
                     }) => {
    const { userCan } = usePermission();

    if (!userCan('view products')) {
        return null;
    }

    const setDeleteProduct = (id) => {
        setDeleteProductId(id);
    };

    const setUpdateProduct = (id) => {
        setUpdateProductId(id);
        if (id){
            handleShow();
        }else{
            handleClose();
        }
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{index + 1}</th>
                <td>{item?.name}</td>
                <td>
                    {userCan('view firms') && item.firm?.id ? (
                        <Link to={`/firms/${item.firm.id}`}>
                            {item.firm.name}
                        </Link>
                    ) : (
                        item.firm?.name || ''
                    )}
                </td>
                <td>
                    {userCan('view warehouses') && item.warehouse?.id ? (
                        <Link to={`/warehouses/${item.warehouse.id}`}>
                            {item.warehouse.name}
                        </Link>
                    ) : (
                        item.warehouse?.name || ''
                    )}
                </td>
                <td>{item?.in_stock}</td>
                <td>{item?.unit?.name}</td>
                <td><MoneyFormat value={item?.price} decimal={2}/></td>
                <td>
                    <div className="d-flex gap-2">
                        {userCan('view products') && (
                            <Link to={`products/${item?.id}`}>
                                <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ width: '32px', height: '32px', padding: 0 }}
                                >
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </Link>
                        )}
                        {userCan('edit products') && (
                            <Button
                                variant="outline-primary"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                                onClick={() => setUpdateProduct(item?.id)}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        )}
                        {userCan('delete products') && (
                            <Button
                                variant="outline-danger"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                                onClick={() => setDeleteProduct(item?.id)}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        )}
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

ProductItem.defaultProps = {
    showActions: true,
};

ProductItem.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        in_stock: PropTypes.number,
        price: PropTypes.number,
        firm: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        }),
        warehouse: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        }),
        unit: PropTypes.shape({
            name: PropTypes.string
        })
    }).isRequired,
    index: PropTypes.number.isRequired,
    handleShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    setDeleteProductId: PropTypes.func.isRequired,
    setUpdateProductId: PropTypes.func.isRequired
};

export default connect(null, {setDeleteProductId, setUpdateProductId})(ProductItem);
