import React, {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import ClientItem from "./ClientItem";
import {getClients} from "../../actions/client";
import ClientCreateForm from "./ClientCreateForm";
import ClientDeleteForm from "./ClientDeleteForm";
import ClientUpdateForm from "./ClientUpdateForm";
import SearchForm from "../layout/SearchForm";
import {Plus} from "react-feather";
import TableSpinner from "../layout/TableSpinner";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";
import { usePermission } from '../../hooks/usePermission';

const Clients = () => {
    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const {clients, loading, numberOfPages, currentPage, total_clients} = useSelector(state => state.client);
    const { userCan } = usePermission();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if (!userCan('view clients')) {
        return <div>You don't have permission to view clients.</div>;
    }

    return (
        <Fragment>
            <div className="container-fluid p-0">

                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong></strong> mijozlar({total_clients}) </h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <SearchForm getPageItems={getClients}/>
                    </div>
                    <div className="col-md-6">
                        <div className="crud_actions text-end">
                            {userCan('create clients') && (
                                <button className="btn btn-primary" onClick={handleShow}>
                                    Klient <Plus/>
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            {loading && (<TableSpinner/>)}
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nomi</th>
                                    <th scope="col">Yetkazuvchi</th>
                                    <th scope="col">Tashkilot xodimi</th>
                                    <th scope="col">INN</th>
                                    <th scope="col">MFO</th>
                                    <th scope="col">Telefon</th>
                                    <th scope="col">Turi</th>
                                    <th scope="col">Bank hisob raqami</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {clients.map((item, index) => (
                                    <ClientItem key={`employee${item.id}`} number={index + 1} client={item}/>
                                ))}
                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPaginationWithManyPages
                            numberOfPages={numberOfPages}
                            getPageItems={getClients}
                            current_page={currentPage}
                        />
                    </div>
                </div>

            </div>


            {userCan('create clients') && (
                <ClientCreateForm show={show} handleShow={handleShow} handleClose={handleClose}/>
            )}

            <ClientDeleteForm/>

            <ClientUpdateForm/>


        </Fragment>
    );
};

export default Clients;
