import React, {Fragment, useEffect, useRef} from "react";
import moment from 'moment'
import {useSelector} from "react-redux";
import {
    getMaterialOptions,
} from "../../actions/material";
import SearchForm from "../layout/SearchForm";
import FilterByMonth from "../layout/FilterByMonth";
import {getParams} from "../../utils/hooks/getParams";
import FilterByMaterial from "../filters/FilterByMaterial";
import FilterByFirm from "../filters/FilterByFirm";
import SupplierMaterialDataRow from "./SupplierMaterialDataRow";
import {getSuppliersMaterialsTransactions} from "../../actions/suppliers_materials_transaction";
import useDates from "../../utils/useDates";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";
import { usePermission } from '../../hooks/usePermission';

const SuppliersMaterialCalculationsNew = () => {
    const { userCan } = usePermission();
    const params = getParams();
    const {dates, updateDates} = useDates(params.month);
    const {
        suppliers,
        loading,
        numberOfPages,
        current_page,
    } = useSelector(state => state.suppliers_materials_transaction);

    // Check base permission first
    if (!userCan('view supplier material transactions')) {
        return <div>You don't have permission to view supplier material transactions.</div>;
    }

    const {material_id} = getParams();
    const scrollableContainer = useRef();

    useEffect(() => {
        if (scrollableContainer.current) {
            scrollableContainer.current.scrollLeft += scrollableContainer.current.scrollHeight * 30;
        }
    }, [scrollableContainer])

    const onMonthChange = (newYearMonth) => {
        updateDates(newYearMonth);
    }

    return (
        <div className={'px-4'}>
            <div className="row mt-4 mb-2 justify-content-between">
                <div className="col-md-3">
                    {userCan('view firm options on supplier material calculations') && (
                        <FilterByFirm getPageItems={getMaterialOptions} is_filter={true}/>
                    )}
                </div>
                
                <div className="col-md-3">
                    <FilterByMaterial 
                        getPageItems={getSuppliersMaterialsTransactions}
                        getMaterialOptions={getMaterialOptions}
                        auto={userCan('auto select material')}
                    />
                </div>
                <div className="col-md-3">
                    <SearchForm getPageItems={getSuppliersMaterialsTransactions}/>
                </div>
                <div className="col-md-3">
                    <FilterByMonth change={onMonthChange} getPageItems={getSuppliersMaterialsTransactions}/>
                </div>
            </div>

            <div className="row mt-3">
                <div className={`loader-container ${loading ? 'loading' : ''} `}></div>

                <div ref={scrollableContainer} className={`tableFixHead ${loading ? 'data_loader' : ''}`}>
                    {material_id && suppliers ? (
                        suppliers.length > 0 ? <table className={'table-hover custom-hover'}>
                            <thead>
                            <tr>
                                <th className="sticky-col" style={{width: "2em"}}>#</th>
                                <th className="sticky-col2">Yetkazuvchi</th>
                                {dates && dates.length ? (
                                    dates.map((item, index, items) => (
                                        items.length - 1 === index ? (
                                            <th key={`date${index}`} className="sticky-col"
                                                style={{textAlign: "center"}}
                                                colSpan="2">
                                                {item}
                                                {moment(new Date()).format('YYYY-MM-DD') === item ? '(bugun)' : ''}
                                            </th>
                                        ) : (
                                            <th key={`date${index}`} className="sticky-col"
                                                style={{textAlign: "center"}}
                                                colSpan="2">{item}
                                            </th>
                                        )
                                    ))
                                ) : (
                                    <td>no dates</td>
                                )}
                                <th className="sticky-col" style={{textAlign: "center", right: 0}}
                                    colSpan="4">Umumiy hisob
                                </th>
                            </tr>
                            <tr>
                                <th className="sticky-col">-</th>
                                <th className="sticky-col2"></th>
                                {dates.map((subitem, index) => (
                                    <Fragment key={`th${index}`}>
                                        <th className="sticky-col"
                                            style={{textAlign: "center"}}>
                                            Material
                                        </th>
                                        <th className="sticky-col"
                                            style={{textAlign: "center"}}>
                                            Narxi
                                        </th>
                                    </Fragment>
                                ))}
                                <th className="sticky-col"
                                    style={{textAlign: "center", minWidth: "100px", right: "300px"}}>
                                    oy hajmi
                                </th>
                                <th className="sticky-col"
                                    style={{textAlign: "center", minWidth: "100px", right: "200px"}}>
                                    oy narxi
                                </th>
                                <th className="sticky-col"
                                    style={{textAlign: "center", minWidth: "100px", right: "100px"}}>
                                    Umumiy hajmi
                                </th>
                                <th className="sticky-col"
                                    style={{textAlign: "center", minWidth: "100px", right: "0px"}}>
                                    Umumiy narxi
                                </th>
                            </tr>
                            </thead>

                            <tbody>
                            {suppliers.map((item, index) => (
                                <SupplierMaterialDataRow
                                    dates={dates}
                                    index={index}
                                    item={item}
                                    key={index}
                                />
                            ))}
                            </tbody>
                        </table> : 'Bu material bo\'yicha import topilmadi'
                    ) : 'Firma va material tanlang!'}
                </div>
            </div>

            <div className="row mt-3">
                <CustomPaginationWithManyPages
                    numberOfPages={numberOfPages}
                    getPageItems={getSuppliersMaterialsTransactions}
                    current_page={current_page}
                />
            </div>

            {userCan('generate supplier material transactions report') && (
                <div className="row mt-3">
                    {/* Add your report component here */}
                </div>
            )}
        </div>
    )
}

export default SuppliersMaterialCalculationsNew
