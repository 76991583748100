import React, {Fragment, useEffect, useRef, useState} from "react";
import moment from 'moment'
import PropTypes from "prop-types";
import {connect, useSelector} from "react-redux";
import {getEmployeesTransactions, updateTransaction} from "../../actions/employees_transaction";
import SearchForm from "../layout/SearchForm";
import {getParams} from "../../utils/hooks/getParams";
import FilterByMonth from "../layout/FilterByMonth";
import FilterByFirm from "../filters/FilterByFirm";
import EmployeeDataRow from "./EmployeeDataRow";
import EmployeeReport from "./EmployeeReport";
import useDates from "../../utils/useDates";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";
import { usePermission } from '../../hooks/usePermission';

//create your forceUpdate hook
function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const EmployeeCalculationsNew = () => {
    const { userCan } = usePermission();
    const forceUpdate = useForceUpdate();

    const {
        employees,
        loading,
        numberOfPages,
        current_page,
    } = useSelector(state => state.employees_transaction);

    // Check base permission first
    if (!userCan('view employee transactions')) {
        return <div>You don't have permission to view employee calculations.</div>;
    }

    const {dates, updateDates} = useDates(getParams().month);
    const scrollableContainer = useRef();
    const [isFirst, setIsFirst] = useState(true);

    useEffect(() => {
        if (employees && employees.length > 0 && employees[0].hasOwnProperty('transactions')) {
            if (isFirst === true && scrollableContainer.current) {
                scrollableContainer.current.scrollLeft += scrollableContainer.current.scrollHeight * 30;
                setIsFirst(false);
            }
        }
    }, [employees])

    const onMonthChange = (newYearMonth) => {
        updateDates(newYearMonth);
    }

    return (
        <div className={'px-4'}>
            <div className="row mt-4 mb-2 justify-content-between">
                {userCan('view firm options on employee calculations') && (
                    <div className="col-md-2">
                        <FilterByFirm getPageItems={getEmployeesTransactions}/>
                    </div>
                )}
                <div className="col-md-3 me-auto">
                    <SearchForm getPageItems={getEmployeesTransactions}/>
                </div>
                <div className="col-md-3 align-self-end ">
                    <FilterByMonth getPageItems={getEmployeesTransactions} change={onMonthChange}/>
                </div>
            </div>

            <div className="row mt-3">
                <div className={`loader-container ${loading ? 'loading' : ''} `}></div>
                <div ref={scrollableContainer} className={`tableFixHead ${loading ? 'data_loader' : ''}`}>

                    <table className={'table-hover custom-hover'}>
                        <thead>
                        <tr>
                            <th className="sticky-col" style={{width: "2em"}}>#</th>
                            <th className="sticky-col2">Hodim</th>
                            {
                                dates?.map((item, index, items) => (
                                    items.length - 1 === index ? (
                                        <th key={`date${index}`} className="sticky-col"
                                            style={{textAlign: "center"}}
                                            colSpan="2">
                                            {item}
                                            {moment(new Date()).format('YYYY-MM-DD') === item ? '(bugun)' : ''}
                                        </th>
                                    ) : (
                                        <th key={`date${index}`} className="sticky-col"
                                            style={{textAlign: "center"}}
                                            colSpan="2">{item}
                                        </th>
                                    )
                                ))
                            }
                            <th className="sticky-col" style={{textAlign: "center", right: 0}}
                                colSpan="4">Oy bo'yicha umumiy hisob
                            </th>
                        </tr>
                        <tr>
                            <th className="sticky-col">-</th>
                            <th className="sticky-col2"></th>
                            {
                                dates.map((subitem, index) => (
                                    <Fragment key={`th${index}`}>
                                        <th className="sticky-col"
                                            style={{textAlign: "center"}}>Ishlagan
                                        </th>
                                        <th className="sticky-col"
                                            style={{textAlign: "center"}}>To'langan
                                        </th>
                                    </Fragment>
                                ))
                            }
                            <th className="sticky-col"
                                style={{textAlign: "center", minWidth: "100px", right: "300px"}}>
                                O'tgan oy qoldig'i
                            </th>
                            <th className="sticky-col"
                                style={{textAlign: "center", minWidth: "100px", right: "200px"}}>
                                Umumiy ishlagan
                            </th>
                            <th className="sticky-col"
                                style={{textAlign: "center", minWidth: "100px", right: "100px"}}>
                                Umumiy to'langan
                            </th>
                            <th className="sticky-col"
                                style={{textAlign: "center", minWidth: "100px", right: "0px"}}> Qoldiq puli
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {employees?.map((item, index) => (
                            <EmployeeDataRow item={item} key={item.id} index={index}
                                             dates={dates}
                                             resetAllRows={forceUpdate}/>
                        ))}
                        </tbody>
                    </table>

                </div>

            </div>

            <div className="row mt-3">
                <CustomPaginationWithManyPages
                    numberOfPages={numberOfPages}
                    getPageItems={getEmployeesTransactions}
                    current_page={current_page}
                />

            </div>
            {userCan('generate employee transactions report') && (
                <EmployeeReport/>
            )}
        </div>

    )
}

EmployeeCalculationsNew.propTypes = {
    getEmployeesTransactions: PropTypes.func.isRequired,
    employees_transaction:
    PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    employees_transaction: state.employees_transaction,
});

export default connect(mapStateToProps, {
    getEmployeesTransactions, updateTransaction
})(EmployeeCalculationsNew);
