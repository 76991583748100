import React, {Fragment, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import UnitDeleteForm from "./UnitDeleteForm";
import UnitUpdateForm from "./UnitUpdateForm";
import {getUnits} from "../../actions/unit";
import UnitCreateForm from "./UnitCreateForm";
import UnitItem from "./UnitItem";
import SearchForm from "../layout/SearchForm";
import {Plus} from "react-feather";
import TableSpinner from "../layout/TableSpinner";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";
import { usePermission } from '../../hooks/usePermission';
import PropTypes from 'prop-types';

const Units = () => {
    const { userCan } = usePermission();
    const {units, loading, numberOfPages, currentPage, total_warehouses} = useSelector(state => state.unit);
    const [show, setShow] = useState(false);

    if (!userCan('view units')) {
        return <div>You don't have permission to view units.</div>;
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Fragment>
            <div className="container-fluid p-0">
                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong></strong> kirim o'lchov birliklari ({units.length})</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <SearchForm getPageItems={getUnits}/>
                    </div>

                    <div className="col-md-6">
                        <div className="crud_actions text-end">
                            {userCan('create units') && (
                                <button className="btn btn-primary" onClick={handleShow}>
                                    O'lchov birligi <Plus/>
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <div className="card w-100 table-responsive">
                            {loading && <TableSpinner/>}
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">O'lchov nomi</th>
                                    <th scope="col">Qisqartmasi</th>
                                    <th scope="col">Qiymati</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {units.map((item, index) => (
                                    <UnitItem 
                                        key={item.id} 
                                        number={index + 1} 
                                        unit={item}
                                    />
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPaginationWithManyPages
                            numberOfPages={numberOfPages}
                            getPageItems={getUnits}
                            current_page={currentPage}
                        />
                    </div>
                </div>
            </div>

            {userCan('create units') && (
                <UnitCreateForm 
                    show={show} 
                    handleClose={handleClose}
                />
            )}

            {userCan('edit units') && (
                <UnitUpdateForm/>
            )}

            {userCan('delete units') && (
                <UnitDeleteForm/>
            )}
        </Fragment>
    );
};

Units.propTypes = {
    getUnits: PropTypes.func.isRequired
};

export default connect(null, {getUnits})(Units);
