import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import WarehouseDeleteForm from "./WarehouseDeleteForm";
import WarehouseUpdateForm from "./WarehouseUpdateForm";
import WarehouseItem from "./WarehouseItem";
import WarehouseCreateForm from "./WarehouseCreateForm";
import {getWarehouses} from "../../actions/warehouse";
import SearchForm from "../layout/SearchForm";
import {Plus} from "react-feather";
import FilterByFirm from "../filters/FilterByFirm";
import FilterByType from "../filters/FilterByType";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";
import TableSpinner from "../layout/TableSpinner";
import { usePermission } from '../../hooks/usePermission';

const Warehouses = () => {
    const { userCan } = usePermission();
    const {warehouses, loading, numberOfPages, currentPage, total_warehouses} = useSelector(state => state.warehouse);
    const [show, setShow] = useState(false);

    if (!userCan('view warehouses')) {
        return <div>You don't have permission to view warehouses.</div>;
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Fragment>
            <div className="container-fluid p-0">
                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong></strong> skladlar ({total_warehouses}) </h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-3">
                        <FilterByFirm getPageItems={getWarehouses}/>
                    </div>
                    <div className="col-3">
                        <FilterByType getPageItems={getWarehouses}/>
                    </div>
                    <div className="col-3">
                        <SearchForm getPageItems={getWarehouses}/>
                    </div>

                    <div className="col-md-3">
                        <div className="crud_actions text-end">
                            {userCan('create warehouses') && (
                                <button className="btn btn-primary" onClick={handleShow}>
                                    Sklad <Plus/>
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <div className="card w-100 table-responsive">
                            {loading && <TableSpinner/>}
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nomi</th>
                                    <th scope="col">Firma</th>
                                    <th scope="col">Skladchi</th>
                                    <th scope="col">Sklad turi</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {warehouses.map((item, index) => (
                                    <WarehouseItem 
                                        key={item.id} 
                                        number={index + 1} 
                                        warehouse={item}
                                    />
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPaginationWithManyPages
                            numberOfPages={numberOfPages}
                            getPageItems={getWarehouses}
                            current_page={currentPage}
                        />
                    </div>
                </div>
            </div>

            {userCan('create warehouses') && (
                <WarehouseCreateForm 
                    show={show} 
                    handleClose={handleClose}
                />
            )}

            {userCan('edit warehouses') && (
                <WarehouseUpdateForm/>
            )}

            {userCan('delete warehouses') && (
                <WarehouseDeleteForm/>
            )}
        </Fragment>
    );
};

Warehouses.propTypes = {
    getWarehouses: PropTypes.func.isRequired
};

export default connect(null, {getWarehouses})(Warehouses);
