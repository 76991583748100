import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect, useDispatch} from 'react-redux';
import {setDeleteWarehouseId, setUpdateWarehouseId} from "../../actions/warehouse";
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { usePermission } from '../../hooks/usePermission';

const getWarehouseType = (type) => {
    const types = {
        product: 'Mahsulot',
        part: 'Zapchast',
        material: "Material"
    };
    return types[type] || ''
}

const WarehouseItem = (props) => {
    const { userCan } = usePermission();
    const {number} = props;
    const {
        id,
        name,
        firm,
        storekeeper,
        type,
        date: created_at,
        showActions,
    } = props.warehouse;

    const dispatch = useDispatch();

    if (!userCan('view warehouses')) {
        return null;
    }

    const setDeleteWarehouse = (id) => {
        dispatch(setDeleteWarehouseId(id));
    };

    const setUpdateWarehouse = (id) => {
        dispatch(setUpdateWarehouseId(id));
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>
                    {userCan('view firms') && firm?.id ? (
                        <Link to={`/firms/${firm.id}`}>
                            {firm.name}
                        </Link>
                    ) : (
                        firm?.name || ''
                    )}
                </td>
                <td>
                    {userCan('view users') && storekeeper?.id ? (
                        <Link to={`/users/${storekeeper.id}`}>
                            {storekeeper.name}
                        </Link>
                    ) : (
                        storekeeper?.name || ''
                    )}
                </td>
                <td>{getWarehouseType(type)}</td>
                <td>
                    <div className="d-flex gap-2">
                        {userCan('view warehouses') && (
                            <Link to={`warehouses/${id}`}>
                                <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ width: '32px', height: '32px', padding: 0 }}
                                >
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </Link>
                        )}
                        {userCan('edit warehouses') && (
                            <Button
                                variant="outline-primary"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                                onClick={() => setUpdateWarehouse(id)}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        )}
                        {userCan('delete warehouses') && (
                            <Button
                                variant="outline-danger"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                                onClick={() => setDeleteWarehouse(id)}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        )}
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

WarehouseItem.defaultProps = {
    showActions: true,
};

WarehouseItem.propTypes = {
    warehouse: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        firm: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        }),
        storekeeper: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        }),
        type: PropTypes.string,
        date: PropTypes.string,
        showActions: PropTypes.bool
    }).isRequired,
    number: PropTypes.number.isRequired
};

export default connect(null)(WarehouseItem);
