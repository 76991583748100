import React, { useState, useEffect } from 'react';
import {
    Table,
    Space,
    Button,
    Modal,
    message,
    Popconfirm,
    Tag,
    Card,
    Row,
    Col,
    Typography
} from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, EllipsisOutlined } from '@ant-design/icons';
import axios from 'axios';
import RoleForm from './RoleForm';

const { Title } = Typography;

const RolesList = () => {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [editingRole, setEditingRole] = useState(null);
    const [viewingPermissions, setViewingPermissions] = useState(null);
    const [permissionsByPage, setPermissionsByPage] = useState({});

    const fetchRoles = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/roles');
            setRoles(response.data.data || []);
        } catch (error) {
            message.error('Failed to fetch roles');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchRoles();
    }, []);

    useEffect(() => {
        if (viewingPermissions) {
            // Group permissions by page
            const grouped = viewingPermissions.reduce((acc, permission) => {
                const pageName = permission.page_name || 'General Permissions';
                if (!acc[pageName]) {
                    acc[pageName] = [];
                }
                acc[pageName].push(permission);
                return acc;
            }, {});
            
            // Sort pages alphabetically, but keep "General Permissions" at the end
            const sortedGroups = {};
            Object.keys(grouped)
                .sort((a, b) => {
                    if (a === 'General Permissions') return 1;
                    if (b === 'General Permissions') return -1;
                    return a.localeCompare(b);
                })
                .forEach(key => {
                    sortedGroups[key] = grouped[key];
                });
            
            setPermissionsByPage(sortedGroups);
        } else {
            setPermissionsByPage({});
        }
    }, [viewingPermissions]);

    const handleDelete = async (roleId) => {
        try {
            await axios.delete(`/roles/${roleId}`);
            message.success('Role deleted successfully');
            fetchRoles();
        } catch (error) {
            message.error('Failed to delete role');
        }
    };

    const handleEdit = (role) => {
        setEditingRole(role);
        setModalVisible(true);
    };

    const handleCreate = () => {
        setEditingRole(null);
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setModalVisible(false);
        setEditingRole(null);
    };

    const handleSave = async (values) => {
        try {
            if (editingRole) {
                await axios.put(`/roles/${editingRole.id}`, values);
                message.success('Role updated successfully');
            } else {
                await axios.post('/roles', values);
                message.success('Role created successfully');
            }
            handleModalClose();
            fetchRoles();
        } catch (error) {
            if (error.response?.data?.errors) {
                const errors = error.response.data.errors;
                Object.keys(errors).forEach(key => {
                    message.error(errors[key][0]);
                });
            } else {
                message.error('Failed to save role');
            }
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Permissions',
            dataIndex: 'permissions',
            key: 'permissions',
            render: (permissions) => {
                if (!permissions?.length) return '-';
                
                const displayLimit = 3;
                const displayPermissions = permissions.slice(0, displayLimit);
                const remainingCount = permissions.length - displayLimit;

                return (
                    <Space wrap size={[0, 8]}>
                        {displayPermissions.map((permission) => (
                            <Tag key={permission.id} color="blue">
                                {permission.display_name || permission.name}
                            </Tag>
                        ))}
                        {remainingCount > 0 && (
                            <Tag 
                                style={{ cursor: 'pointer' }}
                                onClick={() => setViewingPermissions(permissions)}
                            >
                                <EllipsisOutlined /> {remainingCount} more
                            </Tag>
                        )}
                    </Space>
                );
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(record)}
                    >
                        Edit
                    </Button>
                    <Popconfirm
                        title="Are you sure you want to delete this role?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" danger icon={<DeleteOutlined />}>
                            Delete
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div style={{ marginBottom: 16 }}>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleCreate}
                >
                    Create Role
                </Button>
            </div>

            <Table
                dataSource={roles}
                columns={columns}
                rowKey="id"
                loading={loading}
            />

            <Modal
                title="Role Form"
                open={modalVisible}
                onCancel={handleModalClose}
                footer={null}
                destroyOnClose
            >
                <RoleForm
                    initialValues={editingRole}
                    onSave={handleSave}
                    onCancel={handleModalClose}
                />
            </Modal>

            <Modal
                title="Permissions by Page"
                open={!!viewingPermissions}
                onCancel={() => setViewingPermissions(null)}
                footer={null}
                width={800}
            >
                <div style={{ maxHeight: '70vh', overflowY: 'auto', paddingRight: 8 }}>
                    <Row gutter={[16, 16]}>
                        {Object.entries(permissionsByPage).map(([pageName, permissions]) => (
                            <Col span={24} key={pageName}>
                                <Card 
                                    title={
                                        <Space>
                                            <Title level={5} style={{ margin: 0 }}>{pageName}</Title>
                                            <Tag color="processing">{permissions.length}</Tag>
                                        </Space>
                                    }
                                    size="small"
                                >
                                    <Row gutter={[8, 8]}>
                                        {permissions.map(permission => (
                                            <Col span={8} key={permission.id}>
                                                <Tag color="blue" style={{ width: '100%', textAlign: 'center' }}>
                                                    {permission.display_name || permission.name}
                                                </Tag>
                                            </Col>
                                        ))}
                                    </Row>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Modal>
        </div>
    );
};

export default RolesList;
