import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { setDeleteFirmId, setUpdateFirmId } from '../../actions/firms';
import { usePermission } from '../../hooks/usePermission';

const FirmCard = ({ firm }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { userCan } = usePermission();

    const handleEdit = () => {
        dispatch(setUpdateFirmId(firm.id));
    };

    const handleDelete = () => {
        dispatch(setDeleteFirmId(firm.id));
    };

    const handleView = () => {
        history.push(`/firms/${firm.id}`);
    };

    if (!userCan('view firms')) {
        return null;
    }

    const buttonStyle = {
        width: '32px',
        height: '32px',
        padding: 0
    };

    return (
        <Card data-testid={`firm-card-${firm.id}`} className="h-100">
            <Card.Body className="d-flex flex-column">
                <div className="mb-3">
                    <div className="mb-2">
                        <strong>Firma nomi: </strong>
                        <span data-testid="firm-name">{firm.name}</span>
                    </div>
                    {firm.manager && (
                        <div className="mb-2">
                            <strong>Manager: </strong>
                            <Link 
                                to={`/users/${firm.manager.id}`}
                                data-testid="firm-manager"
                            >
                                {firm.manager.name}
                            </Link>
                        </div>
                    )}
                    <div>
                        <strong>Manzil: </strong>
                        <span data-testid="firm-address">{firm.address}</span>
                    </div>
                </div>
                <div className="mt-auto d-flex gap-2 justify-content-end">
                    {userCan('view firms') && (
                        <Button
                            variant="outline-secondary"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={buttonStyle}
                            onClick={handleView}
                            data-testid="view-firm-button"
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                    )}
                    {userCan('edit firms') && (
                        <Button
                            variant="outline-primary"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={buttonStyle}
                            onClick={handleEdit}
                            data-testid="edit-firm-button"
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    )}
                    {userCan('delete firms') && (
                        <Button
                            variant="outline-danger"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={buttonStyle}
                            onClick={handleDelete}
                            data-testid="delete-firm-button"
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    )}
                </div>
            </Card.Body>
        </Card>
    );
};

export default FirmCard;
