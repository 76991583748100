import React, {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import TransportDeleteForm from "./TransportDeleteForm";
import TransportUpdateForm from "./TransportUpdateForm";
import TransportCreateForm from "./TransportCreateForm";
import TransportItem from "./TransportItem";
import {getTransports} from "../../actions/transport";
import SearchForm from "../layout/SearchForm";
import {Plus} from "react-feather";
import TableSpinner from "../layout/TableSpinner";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";
import { usePermission } from '../../hooks/usePermission';

const Transports = () => {
    const { userCan } = usePermission();
    const {transports, loading, numberOfPages, currentPage, total_transports} = useSelector(state => state.transport);
    const [show, setShow] = useState(false);

    if (!userCan('view transports')) {
        return <div>You don't have permission to view transports.</div>;
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Fragment>
            <div className="container-fluid p-0">
                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong></strong> mashinalar ({total_transports}) </h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <SearchForm getPageItems={getTransports}/>
                    </div>

                    <div className="col-md-6">
                        <div className="crud_actions text-end">
                            {userCan('create transports') && (
                                <button className="btn btn-primary" onClick={handleShow}>
                                    Transport <Plus/>
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <div className="card w-100 table-responsive">
                            {loading && <TableSpinner/>}
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nomi</th>
                                    <th scope="col">Mashina raqami</th>
                                    <th scope="col">Haydovchi</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {transports.map((item, index) => (
                                    <TransportItem 
                                        key={item.id} 
                                        number={index + 1} 
                                        transport={item}
                                    />
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                            <CustomPaginationWithManyPages
                                numberOfPages={numberOfPages}
                                getPageItems={getTransports}
                                current_page={currentPage}
                            />
                        </div>
                </div>
            </div>

            {userCan('create transports') && (
                <TransportCreateForm 
                    show={show} 
                    handleClose={handleClose}
                />
            )}

            {userCan('edit transports') && (
                <TransportUpdateForm/>
            )}

            {userCan('delete transports') && (
                <TransportDeleteForm/>
            )}
        </Fragment>
    );
};

export default Transports;
