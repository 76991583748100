import React, {Fragment, useState} from 'react';
import { useSelector} from 'react-redux';
import Spinner from '../layout/Spinner';
import SparePartDeleteForm from "./SparePartDeleteForm";
import SparePartUpdateForm from "./SparePartUpdateForm";
import {getSpareParts} from "../../actions/spare_part";
import SparePartCreateForm from "./SparePartCreateForm";
import SparePartItem from "./SparePartItem";
import SearchForm from "../layout/SearchForm";
import SparepartImportForm from "./SparepartImportForm";
import {Plus} from "react-feather";
import FilterByStock from "../materials/FilterByStock";
import FilterByFirm from "../filters/FilterByFirm";
import {Accordion} from "react-bootstrap";
import CustomPaginationWithManyPages from '../layout/CustomPaginationWithManyPages';
import { usePermission } from '../../hooks/usePermission';

const SpareParts = () => {
    const { userCan } = usePermission();
    const {
        spare_parts,
        loading,
        numberOfPages,
        currentPage,
        total_spare_parts
    } = useSelector(state => state.spare_part);

    const [show, setShow] = useState(false);

    if (!userCan('view spare_parts')) {
        return <div>You don't have permission to view spare parts.</div>;
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return loading ? (
        <Spinner/>
    ) : (
        <Fragment>
            <div className="container-fluid p-0">
                {userCan('import spare_parts') && (
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className={'fs-4'}>Zapchast<strong> qo'shish</strong></div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <SparepartImportForm/>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                )}

                <div className="row mb-2 mt-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong></strong> ehtiyot qismlari ({total_spare_parts}) </h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-3">
                        <FilterByFirm getPageItems={getSpareParts}/>
                    </div>

                    <div className="col-3">
                        <SearchForm getPageItems={getSpareParts}/>
                    </div>

                    <div className="col-3">
                        <FilterByStock getPageItems={getSpareParts}/>
                    </div>

                    <div className="col-md-3">
                        {userCan('create spare_parts') && (
                            <div className="crud_actions text-end">
                                <button className="btn btn-primary" onClick={handleShow}>
                                    Zapchast <Plus/>
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <div className="card w-100 table-responsive">
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nomi</th>
                                    <th scope="col">Miqdori</th>
                                    <th scope="col">Narxi</th>
                                    <th scope="col">O'lchov birligi</th>
                                    <th scope="col">Sklad</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {spare_parts.map((item, index) => (
                                    <SparePartItem 
                                        key={item.id} 
                                        spare_part={item} 
                                        number={index + 1}
                                    />
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPaginationWithManyPages
                            numberOfPages={numberOfPages}
                            getPageItems={getSpareParts}
                            current_page={currentPage}
                        />
                    </div>
                </div>
            </div>

            {userCan('create spare_parts') && (
                <SparePartCreateForm 
                    show={show} 
                    handleClose={handleClose}
                />
            )}

            {userCan('edit spare_parts') && (
                <SparePartUpdateForm/>
            )}

            {userCan('delete spare_parts') && (
                <SparePartDeleteForm/>
            )}
        </Fragment>
    );
};


export default SpareParts;
