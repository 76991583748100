import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import Spinner from '../layout/Spinner';
import FirmDeleteModal from "./FirmDeleteModal";
import FirmUpdateForm from "./FirmUpdateForm";
import FirmCreateForm from "./FirmCreateForm";
import { getFirms } from "../../actions/firms";
import FirmCard from "./FirmCard";
import { Plus } from "react-feather";
import { usePermission } from '../../hooks/usePermission';
import CustomPaginationWithManyPages from '../layout/CustomPaginationWithManyPages';

const Firms = ({ getFirms }) => {
    const {firms: firmItems, loading, numberOfPages, currentPage} = useSelector(state => state.firm);
    const { userCan } = usePermission();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const fetchFirms = async () => {
        await getFirms(1);
    };

    useEffect(() => {
        fetchFirms();
    }, [getFirms]);

    const canViewFirms = userCan('view firms');
    const canCreateFirms = userCan('create firms');

    console.log('Firms component permissions:', { canViewFirms, canCreateFirms });

    if (!canViewFirms) {
        return <div>You don't have permission to view firms.</div>;
    }

    return loading ? (
        <Spinner/>
    ) : (
        <Fragment>
            <div className="container-fluid p-0">
                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong>Firmalar</strong>({firmItems?.length || 0}) </h1>
                    </div>
                    <div className="col-lg-4">
                        <div className="crud_actions text-end">
                            {canCreateFirms && (
                                <button className="btn btn-primary" onClick={handleShow}>
                                    Firma <Plus/>
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    {firmItems?.map((item, index) => (
                        <div key={`firm${item.id}`} className="col-md-4 col-lg-4 mb-3">
                            <FirmCard firm={item} index={index}/>
                        </div>
                    ))}
                </div>

                {numberOfPages > 1 && (
                    <CustomPaginationWithManyPages
                        numberOfPages={numberOfPages}
                        currentPage={currentPage}
                        getPageItems={getFirms}
                    />
                )}
            </div>

            {canCreateFirms && (
                <FirmCreateForm show={show} handleShow={handleShow} handleClose={handleClose}/>
            )}

            <FirmDeleteModal/>
            <FirmUpdateForm/>
        </Fragment>
    );
};

Firms.propTypes = {
    getFirms: PropTypes.func.isRequired
};

export default connect(null, { getFirms })(Firms);
