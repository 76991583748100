import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteTransportId, setUpdateTransportId} from "../../actions/transport";
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { usePermission } from '../../hooks/usePermission';

const TransportItem = ({
                           setDeleteTransportId,
                           setUpdateTransportId,
                           transport: {
                               id, name, driver_id, plate,
                               driver,
                               date: created_at
                           },
                           showActions,
                           number
                       }) => {
    const { userCan } = usePermission();

    if (!userCan('view transports')) {
        return null;
    }

    const setDeleteTransport = (id) => {
        setDeleteTransportId(id);
    };

    const setUpdateTransport = (id) => {
        setUpdateTransportId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>{plate}</td>
                <td>
                    {userCan('view users') && driver?.id ? (
                        <Link to={`/users/${driver.id}`}>
                            {`${driver?.first_name || ''}  ${driver?.last_name || ''}`}
                        </Link>
                    ) : (
                        `${driver?.first_name || ''}  ${driver?.last_name || ''}`
                    )}
                </td>
                <td>
                    <div className="d-flex gap-2">
                        {userCan('view transports') && (
                            <Link to={`transports/${id}`}>
                                <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ width: '32px', height: '32px', padding: 0 }}
                                >
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </Link>
                        )}
                        {userCan('edit transports') && (
                            <Button
                                variant="outline-primary"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                                onClick={() => setUpdateTransport(id)}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        )}
                        {userCan('delete transports') && (
                            <Button
                                variant="outline-danger"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                                onClick={() => setDeleteTransport(id)}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        )}
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

TransportItem.defaultProps = {
    showActions: true,
};

TransportItem.propTypes = {
    transport: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        driver_id: PropTypes.number,
        plate: PropTypes.string,
        driver: PropTypes.shape({
            id: PropTypes.number,
            first_name: PropTypes.string,
            last_name: PropTypes.string
        }),
        date: PropTypes.string
    }).isRequired,
    showActions: PropTypes.bool,
    number: PropTypes.number.isRequired,
    setDeleteTransportId: PropTypes.func.isRequired,
    setUpdateTransportId: PropTypes.func.isRequired
};

export default connect(null, {setDeleteTransportId, setUpdateTransportId})(TransportItem);
