import React, { useState, useEffect } from 'react';
import {
    Table,
    Space,
    Button,
    Modal,
    message,
    Popconfirm,
    Tag,
    Input,
    Select
} from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import PermissionForm from './PermissionForm';

const PermissionsList = () => {
    const [permissions, setPermissions] = useState([]);
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [editingPermission, setEditingPermission] = useState(null);
    const [selectedPage, setSelectedPage] = useState(null);
    const [searchText, setSearchText] = useState('');

    const fetchPages = async () => {
        try {
            const response = await axios.get('/pages');
            setPages(response.data.data || []);
        } catch (error) {
            message.error('Failed to fetch pages');
        }
    };

    const fetchPermissions = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/permissions', {
                params: {
                    page_id: selectedPage,
                    search: searchText
                }
            });
            setPermissions(response.data.data || []);
        } catch (error) {
            message.error('Failed to fetch permissions');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchPages();
    }, []);

    useEffect(() => {
        fetchPermissions();
    }, [selectedPage, searchText]);

    const handleDelete = async (permissionId) => {
        try {
            await axios.delete(`/permissions/${permissionId}`);
            message.success('Permission deleted successfully');
            fetchPermissions();
        } catch (error) {
            message.error('Failed to delete permission');
        }
    };

    const handleEdit = (permission) => {
        setEditingPermission(permission);
        setModalVisible(true);
    };

    const handleCreate = () => {
        setEditingPermission(null);
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setModalVisible(false);
        setEditingPermission(null);
    };

    const handleSave = async (values) => {
        try {
            if (editingPermission) {
                await axios.put(`/permissions/${editingPermission.id}`, values);
                message.success('Permission updated successfully');
            } else {
                await axios.post('/permissions', values);
                message.success('Permission created successfully');
            }
            handleModalClose();
            fetchPermissions();
        } catch (error) {
            message.error('Failed to save permission');
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Display Name',
            dataIndex: 'display_name',
            key: 'display_name',
        },
        {
            title: 'Pages',
            dataIndex: 'pages',
            key: 'pages',
            render: (pages) => (
                <>
                    {pages?.map((page) => (
                        <Tag key={page.id} color="blue">
                            {page.display_name}
                        </Tag>
                    ))}
                </>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(record)}
                    >
                        Edit
                    </Button>
                    <Popconfirm
                        title="Are you sure you want to delete this permission?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" danger icon={<DeleteOutlined />}>
                            Delete
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Select
                    style={{ width: 300 }}
                    placeholder="Filter by page"
                    allowClear
                    onChange={setSelectedPage}
                    value={selectedPage}
                    options={pages.map(page => ({
                        value: page.id,
                        label: page.display_name
                    }))}
                />
                <Input
                    placeholder="Search permissions..."
                    prefix={<SearchOutlined />}
                    style={{ width: 300 }}
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                />
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleCreate}
                >
                    Add Permission
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={permissions}
                rowKey="id"
                loading={loading}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    showTotal: (total) => `Total ${total} items`,
                }}
            />
            <Modal
                title={editingPermission ? 'Edit Permission' : 'Create Permission'}
                open={modalVisible}
                onCancel={handleModalClose}
                footer={null}
                width={600}
            >
                <PermissionForm
                    initialValues={editingPermission}
                    onSave={handleSave}
                    onCancel={handleModalClose}
                />
            </Modal>
        </div>
    );
};

export default PermissionsList;
