import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    setDeleteExpenseTemplateId,
    setUpdateExpenseTemplateId
} from "../../actions/expense_template";
import {getExpenseTemplateType} from "../../utils/hooks/getParams";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { usePermission } from '../../hooks/usePermission';

const ExpenseTemplateItem = ({
    setDeleteExpenseTemplateId,
    setUpdateExpenseTemplateId,
    index,
    expense_template: {
        id, name, category, type, color,
    },
}) => {
    const { userCan } = usePermission();

    if (!userCan('view expense templates')) {
        return null;
    }

    const setDeleteExpenseTemplate = (id) => {
        setDeleteExpenseTemplateId(id);
    };

    const setUpdateExpenseTemplate = (id) => {
        setUpdateExpenseTemplateId(id);
    };

    return (
        <tr>
            <th scope="row">{index}</th>
            <td>{name}</td>
            <td>{category}</td>
            <td style={{backgroundColor: color ?? '#000'}}>{color}</td>
            <td>{getExpenseTemplateType(type)}</td>
            <td>
                <div className="d-flex gap-2">
                    {userCan('edit expense templates') && (
                        <Button
                            variant="outline-primary"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                            onClick={() => setUpdateExpenseTemplate(id)}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    )}
                    {userCan('delete expense templates') && (
                        <Button
                            variant="outline-danger"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                            onClick={() => setDeleteExpenseTemplate(id)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    )}
                </div>
            </td>
        </tr>
    );
};

ExpenseTemplateItem.propTypes = {
    expense_template: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        color: PropTypes.string
    }).isRequired,
    index: PropTypes.number.isRequired,
    setDeleteExpenseTemplateId: PropTypes.func.isRequired,
    setUpdateExpenseTemplateId: PropTypes.func.isRequired
};

export default connect(null, {
    setDeleteExpenseTemplateId, 
    setUpdateExpenseTemplateId
})(ExpenseTemplateItem);
