import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteSparePartId, setUpdateSparePartId} from "../../actions/spare_part";
import {Link} from 'react-router-dom'
import MoneyFormat from "../layout/MoneyFormat";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bootstrap';
import { usePermission } from '../../hooks/usePermission';

const SparePartItem = ({
                           setDeleteSparePartId,
                           setUpdateSparePartId,
                           spare_part: {
                               id, name, quantity, price, warehouse_id, warehouse, unit, unit_id,
                               date: created_at
                           },
                           number
                       }) => {
    const { userCan } = usePermission();

    if (!userCan('view spare_parts')) {
        return null;
    }

    const setDeleteSparePart = (id) => {
        setDeleteSparePartId(id);
    };

    const setUpdateSparePart = (id) => {
        setUpdateSparePartId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>{quantity}</td>
                <td><MoneyFormat value={price}/> so'm</td>
                <td>{unit?.name}</td>
                <td>
                    {userCan('view warehouses') && warehouse?.id ? (
                        <Link to={`warehouses/${warehouse.id}`}>{warehouse?.name}</Link>
                    ) : (
                        warehouse?.name
                    )}
                </td>
                <td>
                    <div className="d-flex gap-2">
                        {userCan('edit spare_parts') && (
                            <Button
                                variant="outline-primary"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{width: '32px', height: '32px', padding: 0}}
                                onClick={() => setUpdateSparePart(id)}
                            >
                                <FontAwesomeIcon icon={faEdit}/>
                            </Button>
                        )}
                        {userCan('delete spare_parts') && (
                            <Button
                                variant="outline-danger"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{width: '32px', height: '32px', padding: 0}}
                                onClick={() => setDeleteSparePart(id)}
                            >
                                <FontAwesomeIcon icon={faTrash}/>
                            </Button>
                        )}
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

SparePartItem.defaultProps = {
    showActions: true,
};

SparePartItem.propTypes = {
    spare_part: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        quantity: PropTypes.number,
        price: PropTypes.number,
        warehouse_id: PropTypes.number,
        warehouse: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        }),
        unit: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        }),
        unit_id: PropTypes.number,
        date: PropTypes.string
    }).isRequired,
    number: PropTypes.number.isRequired,
    setDeleteSparePartId: PropTypes.func.isRequired,
    setUpdateSparePartId: PropTypes.func.isRequired
};

export default connect(null, {setDeleteSparePartId, setUpdateSparePartId})(SparePartItem);
