import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteClientId, setUpdateClientId} from "../../actions/client";
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrash, faEye} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bootstrap';
import { usePermission } from '../../hooks/usePermission';

const ClientItem = ({
                        auth,
                        setDeleteClientId,
                        setUpdateClientId,
                        client: {
                            id,
                            name,
                            contractor,
                            inn,
                            mfo,
                            phone,
                            type,
                            bank_account,
                            date: created_at,
                            supplier
                        },
                        number
                    }) => {

    const { userCan } = usePermission();

    const setDeleteClient = (id) => {
        setDeleteClientId(id);
    };

    const setUpdateClient = (id) => {
        setUpdateClientId(id);
    };

    if (!userCan('view clients')) {
        return null;
    }

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>
                    <Link to={`suppliers/${supplier?.id}`}>
                        {supplier?.name || ''}
                    </Link>
                </td>
                <td>{contractor}</td>
                <td>{inn}</td>
                <td>{mfo}</td>
                <td>{phone}</td>
                <td>{type}</td>
                <td>{bank_account}</td>
                <td>
                    <div className="d-flex gap-2">
                        {userCan('view clients') && (
                            <Link to={`clients/${id}`}>
                                <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{width: '32px', height: '32px', padding: 0}}
                                >
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </Link>
                        )}
                        {userCan('edit clients') && (
                            <Button
                                variant="outline-primary"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{width: '32px', height: '32px', padding: 0}}
                                onClick={() => setUpdateClient(id)}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        )}
                        {userCan('delete clients') && (
                            <Button
                                variant="outline-danger"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{width: '32px', height: '32px', padding: 0}}
                                onClick={() => setDeleteClient(id)}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        )}
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

ClientItem.defaultProps = {
    showActions: true,
};

ClientItem.propTypes = {
    client: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    setDeleteClientId: PropTypes.func.isRequired,
    setUpdateClientId: PropTypes.func.isRequired,
    number: PropTypes.number
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteClientId, setUpdateClientId})(ClientItem);
